<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h1 class="display-2 font-weight-light">Checkout</h1>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5" v-if="loading">
            <v-col style="text-align: center">
                 <p class="headline">Loading...</p>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5" v-show="items.length > 0">
            <v-col style="text-align: center">
                <div v-for="(item, index) in items" v-bind:key="index">
                    <p class="headline">{{item.productName}}</p>
                    <p class="caption" v-if="item.nickname">{{item.nickname}}</p>
                    <p class="headline">{{item.price}}</p>
                    <p class="caption" v-if="item.type === 'recurring'">Recurring</p> <!-- TODO: show frequency -->
                </div>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5" v-if="items.length > 0">
            <v-col style="text-align: center">
                 <v-btn color="blue" class="white--text" @click="payWithStripe">Buy</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5" v-if="items.length > 0">
            <v-col style="text-align: center">
                <v-btn color="grey" class="white--text" @click="cancelPaymentInteraction">Cancel</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5" v-if="isViewCancel">
            <v-col style="text-align: center">
                 <v-btn color="grey" class="white--text">Cancel</v-btn>
            </v-col>
        </v-row>
        </v-col>
    </SectionLayout>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { Client } from '@/client';
import SectionLayout from '@/components/SectionLayout.vue';

const client = new Client();

// const { stripe } = window;

// unit_amount is always an integer; in USD it would be the number of cents, so unit_amount/100 is the price.
// returns formatted price, for example 900 => '$9.00'
function toPrice(unitAmount) {
    const dollars = Math.floor(unitAmount / 100);
    const cents = unitAmount - (dollars * 100);
    const centsWithPadding = cents < 10 ? `0${cents}` : cents;
    return `$${dollars}.${centsWithPadding}`;
}

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            loading: true,
            interactionId: null,
            items: [], // { priceId, productId, productName, price, nickname, type 'payment' or 'recurring' }
            // priceId: null,
            // productId: null,
            // productName: null,
            // price: null,
            // nickname: null,
            // type: null, // e.g. "recurring"
            // stripeCheckoutSessionId: null,
            // stripeTokenPublicKey: null,
            isViewCancel: false,
        };
    },
    computed: {
        ...mapState({
            // isReady: (state) => state.isReady,
            session: (state) => state.session,
            serviceContext: (state) => state.serviceContext,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    methods: {
        init() {
            // if (this.isReady && this.isAuthenticated) {
            // this.$router.push('/dashboard');
            // }
            // look for required query parameters
            const {
                priceId, productId, i: interactionId, view,
            } = this.$route.query; // this refers to a distinct product+price; when using stripe it's a priceId and we can look up the productId from this
            // const redirectLocationOnPurchase = this.$route.query.onPurchase; // a URL; domain must be cryptium.com or subdomain, or else we don't redirect and simply offer a link to proceed to customer.cryptium.com
            // const redirectLocationOnCancel = this.$route.query.onCancel; // a URL; domain must be cryptium.com or subdomain, or else we don't redirect and simply offer a link to proceed to customer.cryptium.com
            console.log(`init: priceId ${priceId} interactionId ${interactionId}`);
            if (interactionId) {
                this.loadPaymentInteraction(interactionId);
            } else if (view === 'cancel') {
                this.isViewCancel = true; // indicates user returned from stripe with the cancel button, so we will show the order summary and allow the user to try again or return to the site
            } else if (productId) {
                this.loadProductInfo();
            } else if (priceId) {
                this.createCheckoutSession(priceId); // loadStripeProductInfoByPriceId(priceId)
            } else {
                this.$router.replace('/error?type=invalid-request');
            }
        },
        loadProductInfo() {
            console.log('loadProductInfo: TODO: NOT IMPLEMENTED YET');
            this.loading = false;
            // TODO: if item not found, redirect to /error?type=unknown-item&incidentId=<incidentId>  (with an incident id we got from server, so we can forward it in query parameter, and customer support will be able to find the item id or other information in the server log when they search for this incidentId)
        },
        async loadPaymentInteraction(interactionId) {
            try {
                console.log(`loadPaymentInteraction: interactionId ${interactionId}`);
                const response = await client.payment.getPaymentInteractionById(interactionId);
                console.log(`loadPaymentInteraction: response ${JSON.stringify(response)}`);
                this.interactionId = response.id;
                this.items.push(...response.items);
                // this.stripeCheckoutSessionId = response.checkout;
                // this.stripeTokenPublicKey = response.stripeTokenPublicKey;
                this.loading = false;
                // TODO: if item not found, redirect to /error?type=unknown-item&incidentId=<incidentId>  (with an incident id we got from server, so we can forward it in query parameter, and customer support will be able to find the item id or other information in the server log when they search for this incidentId)
            } catch (err) {
                console.error('loadPaymentInteraction failed', err);
            }
        },
        async loadStripeProductInfoByPriceId(priceId) {
            console.log(`loadStripeProductInfoByPriceId: ${priceId}`);
            const response = await client.payment.getProductInfoByPriceId(priceId);
            this.loading = false;

            if (response) {
                console.log(`loadStripeProductInfoByPriceId: response ${JSON.stringify(response)}`);

                // {"priceId":"price_1HeOALIH2Lioi6Ltnl2vPx1k","nickname":"$9/month","type":"recurring","unit_amount":900,"productId":"prod_IEru5SHbCiCUjr","productName":"Cryptium VPN"}
                this.priceId = response.priceId; // in case server wants to "redirect" to another item
                this.nickname = response.nickname;
                this.type = response.type;
                this.price = toPrice(response.unit_amount);
                this.productId = response.productId;
                this.productName = response.productName;
            } else {
                this.$router.replace({ path: '/error', query: { type: 'unknown-item' } }); // TODO: include incidentId from response; whichi means it's not an "else", it's a  if response.error ...
            }
        },
        // this is a combination of creating a new interaction and proceeding to stripe
        async createCheckoutSession(priceId) {
            console.log(`createCheckoutSession: ${priceId}`);

            // create a payment interaction with a single item
            const interaction = await client.payment.createPaymentInteraction({ items: [{ priceId }] });
            console.log(`createCheckoutSession: interaction response: ${JSON.stringify(interaction)}`);

            // replace current url with interaction id so if user taps 'back' button from stripe (without using their cancel button) we still have the interaction id
            // since the path is the same as the current page, this will NOT cause the vue to be re-mounted and we can continue processing
            this.$router.replace({ path: '/checkout', query: { i: interaction.id } });

            const response = await client.payment.createCheckoutSession({ interactionId: interaction.id });
            console.log(`createCheckoutSession: response ${JSON.stringify(response)}`);

            if (response) {
                /*
                // {"priceId":"price_1HeOALIH2Lioi6Ltnl2vPx1k","nickname":"$9/month","type":"recurring","unit_amount":900,"productId":"prod_IEru5SHbCiCUjr","productName":"Cryptium VPN"}
                this.priceId = response.priceId; // in case server wants to "redirect" to another item
                this.nickname = response.nickname;
                this.type = response.type;
                this.price = toPrice(response.unit_amount);
                this.productId = response.productId;
                this.productName = response.productName;
                */

                if (response.checkout && response.stripeTokenPublicKey) {
                    const stripe = new window.Stripe(response.stripeTokenPublicKey); // Stripe library loaded from js.stripe.com (see index.html)
                    // response includes a stripe checkout session id, we can redirect to stripe
                    const result = await stripe.redirectToCheckout({ sessionId: response.checkout });
                    console.log(`createCheckoutSesssion: redirect response ${JSON.stringify(result)}`);
                    if (result.error) {
                        this.$router.replace({ path: '/error', query: { type: 'checkout-failed', i: interaction.id } }); // TODO: include incidentId from response; whichi means it's not an "else", it's a  if response.error ...
                    }
                }
            } else {
                this.$router.replace({ path: '/error', query: { type: 'unknown-item', i: interaction.id } }); // TODO: include incidentId from response; whichi means it's not an "else", it's a  if response.error ...
            }

            this.loading = false;
        },
        // this is a combination of creating a new interaction and proceeding to stripe
        async payWithStripe() {
            try {
                console.log(`payWithStripe: ${this.interactionId}`);

                const response = await client.payment.createCheckoutSession({ interactionId: this.interactionId });
                console.log(`payWithStripe: response ${JSON.stringify(response)}`);

                if (response && response.checkout && response.stripeTokenPublicKey) {
                    const stripe = new window.Stripe(response.stripeTokenPublicKey); // Stripe library loaded from js.stripe.com (see index.html)
                    // response includes a stripe checkout session id, we can redirect to stripe
                    const result = await stripe.redirectToCheckout({ sessionId: response.checkout });
                    console.log(`createCheckoutSesssion: redirect response ${JSON.stringify(result)}`);
                    if (result.error) {
                        this.$router.replace({ path: '/error', query: { type: 'checkout-failed', i: this.interactionId } }); // TODO: include incidentId from response; whichi means it's not an "else", it's a  if response.error ...
                    }
                } else {
                    this.$router.replace({ path: '/error', query: { type: 'checkout-failed', i: this.interactionId } }); // TODO: include incidentId from response; whichi means it's not an "else", it's a  if response.error ...
                }

                this.loading = false;
            } catch (err) {
                console.error('payWithStripe failed', err);
            }
        },
        async cancelPaymentInteraction() {
            if (this.interactionId) {
                const isEdited = await client.payment.cancelPaymentInteractionById(this.interactionId);
                console.log(`cancelPaymentInteraction isEdited ${isEdited}`);
                // TODO: if the interaction contains a return url for cancelled orders, then go there
            }

            window.location = 'https://cryptium.com'; // default behavior is return to main website
        },
    },
    // watch: {
    //     isReady(value, oldValue) {
    //         if (value && !oldValue) {
    //             this.init();
    //         }
    //     },
    // },
    mounted() {
        this.init();
    },
};
</script>
